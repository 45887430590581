const ORM_APIURL = 'https://j2il0996wk.execute-api.us-west-2.amazonaws.com/icompliance/'; //icompliance
const FUNC_APIURL = 'https://xklpkhmss1.execute-api.us-west-2.amazonaws.com/icompliance/'; //icompliance

export const environment = {
  production: false,
  grupoEconomico  : ORM_APIURL + 'SstGrupoEconomicoFunc',
  sociedad        : ORM_APIURL + 'SstSociedadFunc',
  grupoCentro     : ORM_APIURL + 'SstGrupoCentrosFunc',
  centro          : ORM_APIURL + 'SstCentroFunc',
  rolURL          : ORM_APIURL + 'SstRolFunc',
  tipoIdentificacionURL: ORM_APIURL + 'SstTipoIdentificacionFunc',
  moduloURL       : ORM_APIURL + 'SstModuloFunc',
  userURL         : ORM_APIURL + 'SstUsuarioFunc',
  userEntitieRolURL: ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  rolModuloURL    : ORM_APIURL + 'SstRolModuloFunc',
  formatoModuloURL: ORM_APIURL + 'SstFormatoModuloFunc',
  versionURL      : ORM_APIURL + 'SstVersionFunc',
  userSocRolURL   : ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  userEstOrgURL   : ORM_APIURL + 'SstUsuarioEstOrgFunc',
  adjuntosModulos : ORM_APIURL + 'SstAdjuntosModulosFunc',
  parametroURL    : ORM_APIURL + 'SstParametroFunc',
  usuarioAccion   : ORM_APIURL + 'SstUsuarioAccionFunc',
  activEconomURL  : ORM_APIURL + 'SstActividadEconomicaFunc',
  eventoURL       : ORM_APIURL + 'SstEventoFunc',
  normativaURL    : ORM_APIURL + 'SstNormativaFunc',
  perfilURL       : ORM_APIURL + 'SstPerfilFunc',
  userSocPerfilURL: ORM_APIURL + 'SstUsuarioSocPerfilFunc',
  consumedStorage : FUNC_APIURL + 'sst_ConsumedStorage',
  S3_URL          : 'https://twqm0b7nh4.execute-api.us-west-2.amazonaws.com/icompliance/',
  modulosUserS    : FUNC_APIURL + 'sst_BizModulosUserS', // ok
  dataUser        : FUNC_APIURL + 'sst_BizDataUser', // ok
  moduloTreeURL   : FUNC_APIURL + 'sst_ModulosTree', // linea por eliminar
  // entitieAllURL   : FUNC_APIURL + 'sst_GenGrupoEcoAllOrdered', linea por eliminar
  entitieIdURL    : FUNC_APIURL + 'sst_GenGrupoEcoByIdOrdered', //ok
  modulosByRolURL : FUNC_APIURL + 'sst_ModulosByRol', // ok
  activeModulURL  : FUNC_APIURL + 'sst_ActiveModul', //ok
  usersEntitie    : FUNC_APIURL + 'sst_UsersEntitie ', // ok
  userRols        : FUNC_APIURL + 'sst_UserRols', // ok 
  treeSociedad    : FUNC_APIURL + 'sst_BizTreeSociedad', //ok
  // modulosUser     : FUNC_APIURL + 'sst_BizModulosUser', linea por eliminar
  // numberFiles     : FUNC_APIURL + 'biznumberfiles', linea por eliminar
  modulosAccion   : FUNC_APIURL + 'sst_BizModulosAcc', //ok
  deleteEstOrg    : FUNC_APIURL + 'sst_BizDeleteEstOrg', //ok 
  verifyExist     : FUNC_APIURL + 'sst_BizVerifyExist', //ok
  saveUserWithoutContract: FUNC_APIURL + 'sst_BizSaveUserWithoutContract', //ok
  modulosbyparent : FUNC_APIURL + 'sst_BizModulosByParent',
  userChangeKey   : FUNC_APIURL + 'sst_UserChangeKey',
  userProfiles    : FUNC_APIURL + 'sst_UserProfiles',
};

export const AWSInfo = {              // qas
  s3Bucket: 'qas.icompliance.files',
  logoPortal: 'qas.icompliance.files/compliance-logo/compliance-logo-141.png',
  fileTerms : 'qas.icompliance.files/compliance-logo/230111 iCompliance Términos y Condiciones de Uso.pdf'
};

export const cognitoConfig = {        // qas
  userPool: {
      UserPoolId: 'us-west-2_ZcTLBisSD',
      ClientId: '3q8b18jmdqm57jnks9le2tvtoa',
      region: 'us-west-2'
  },
  identityPoolId: 'us-west-2:66ab9ea3-caed-4a32-94b7-3f5e06a20c3b'
};

export const teletrabajo = {
  modalityPresencial  : 'Presencial',
  modalityTeletrabajo : 'Teletrabajo',
  tiles               : [37,38,41,39]
};

export const gestion = {
  frontSST  : 1,
  default   : 1,
  supervisor : [16,36,34,41,45,141,115,117,119,120,121,122,123,124,130,134,135,136,
    50,114,118,113,125,129,131,132,133,137,138,139,140,116,126,127,128]
};

export const staff = {
  health    : 24,
  supplier  : 34,
  userSST   : 28
};

export const permission = {
  edit  : 1,
  view  : 2,
  SO    : 13,
  arrTilesSO    : [56,58,59],
  onlyViewRoles : [25,32,73,29,72,26,30,74],
};