import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../../../app/auth.service';
import { NavigationService } from 'src/@vex/services/navigation.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  @Input() currentUser: string;
  @Input() photoUser: string;
  dropdownOpen: boolean;
  icPerson = icPerson;
  constructor(private popover: PopoverService,  
            private http: HttpClient,
            private authService: AuthService,
            private navigationService: NavigationService,
            private cd: ChangeDetectorRef) { }

  ngOnInit() {
    var a=this.authService.getAuthenticatedUser();
    // console.info(a);
    var that=this;
    this.authService.getAuthenticatedUser().getSession((err,session)=>{
          if(err){
            return;
          }else{
            // debugger;
            // console.info(session.getIdToken().payload['cognito:username']);
            // console.info("groups:"+session.getIdToken().payload['cognito:groups']);
            // console.info("Decode:",session.getIdToken().decodePayload());
            // that.currentUser=session.getIdToken().decodePayload().name;
            /***************solo para demo */
            // switch (session.getIdToken().decodePayload().email) {
            //   case 'adm-colombia@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/14.jpg'; break;
            //   case 'user-asei@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/19.jpg'; break;
            //   case 'bumeran@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/5.jpg'; break;
            //   case 'earce@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/2.jpg'; break;
            //   case 'rflores@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/1.jpg'; break;
            //   case 'clozano@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/14.jpg'; break;
            //   default: 
            //     break;
            // }

            // console.log(session.getIdToken().decodePayload().email);
            // this.setUserDefault(session.getIdToken().decodePayload().email);

            //Load Permisos:
            // let headers = new HttpHeaders();
            // headers = headers.set('Authorization', session.getIdToken().getJwtToken());
            // <NavigationItem[]>
            // this.http.get<respermiso>('https://eqw5ty3rg2.execute-api.us-east-2.amazonaws.com/dev/permisos', {observe: 'response',headers:headers})
            // .subscribe(response => {   // data is of type HttpResponse<Object>

            //   console.info("response: permisos");
            //   console.info(response.body.body);
            //   console.info(response.body.body.modulos);
              // console.info(response.body['modulos']);
              // for (let index = 0; index < response.body.body.modulos.length; index++) {
              //   this.navigationService.items.push(response.body.body.modulos[index]);
              // }
              // response.body.body.modulos.forEach(element => {
              //   this.navigationService.items.push(element);
              // });
                //console.log(response.headers.get('X-Custom-Header'));
                //console.log(response.body['someProperty']); //response.body is a JSON
            // });
            
          }
    });

  }
  
  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

}
