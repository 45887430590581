export const arrModalidadTrabajo = [
    {tipoModalidad: 'P', nombreModalidad: 'Presencial'},
    {tipoModalidad: 'A', nombreModalidad: 'Teletrabajo Parcial'},
    {tipoModalidad: 'T', nombreModalidad: 'Teletrabajo Total'}
  ];

  export const arrPerfiles = [
    {idPerfil: 1, idRole: 28, desPerfil: 'Suscriptor'},
    {idPerfil: 2, idRole: 24, desPerfil: 'Personal de Salud Ocupacional'},
    {idPerfil: 3, idRole: 34, desPerfil: 'Tercero'}
  ];

  export const arrPerfilPSOThird = [
    {idPerfil: 2, idRole: 24, desPerfil: 'Personal de Salud Ocupacional'},
    {idPerfil: 3, idRole: 34, desPerfil: 'Tercero'}
  ];

  export const arrPerfilPSO = [
    {idPerfil: 2, idRole: 24, desPerfil: 'Personal de Salud Ocupacional'},
  ];

  export const arrPerfilThird = [
    {idPerfil: 3, idRole: 34, desPerfil: 'Tercero'}
  ];