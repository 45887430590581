import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserEstorgService {

  constructor(private http: HttpClient) {  }

  saveUserEstOrg(dataEstOrg: any) { 
    const dataUser = {action:'save', payload: dataEstOrg};
    return this.http.post(environment.userEstOrgURL, dataUser);
  }

  saveAllUserEstOrg(entitieId: number, userId: number,arrEstOrgSelected: any[]) {
    let arrSaveUserEstOrg = []
    arrEstOrgSelected.forEach(element => {
      let dataUserEstOrg = {
        idSociedad: entitieId,
        idUsuario: userId,
        idEstOrg: element.idSociedad | element.idGrupoCentros | element.idCentro,
        tipoEstOrg: this.findLevel(element),
        idParent: element.idParent | 0
      }
      arrSaveUserEstOrg.push(this.saveUserEstOrg(dataUserEstOrg));
    });
    return forkJoin(arrSaveUserEstOrg);
  }

  deleteAllUserEstOrg(userId: number, entitieId: number) { 
    const req = {userId:userId, entitieId: entitieId};
    return this.http.post(environment.deleteEstOrg, req);
  }
  deleteUserEstOrg(id: number) { 
    const dataUserEstOrg = {action:'delete', payload: {idUsuarioEstOrg: id}};
    return this.http.post(environment.userEstOrgURL, dataUserEstOrg);
  }

  private findLevel(node: any):number {
    if (Object.keys(node).includes('idSociedad')) {
      return 1;
    }
    if (Object.keys(node).includes('idGrupoCentros')) {
      return 2;
    }
    if (Object.keys(node).includes('idCentro')) {
      return 3;
    }
  }
}
