<div fxLayout="row"  fxLayoutAlign="start" class="pb-4">
  <h2 class="headline block font-medium text-primary border-b" fxFlex="auto" fxLayout="row">Tile(s)</h2>
  <!-- <button mat-raised-button
          color="primary" 
          (click)="assignModulosToRol()" disabled="true">Modificar Tile(s)***</button> -->
</div>


<!-- <div class="pb-6" fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button 
          color="primary" 
          (click)="assignModulosToRol()">Asignar Tiles</button>
</div> -->
          <!-- <p>rol {{rolIdSelect}} </p> -->
          <!-- <p>total {{dataRolModulo}} </p> -->
          <!-- <p>tree {{treeDataModulo | json}} </p>  -->
<input type="text" [(ngModel)]="dataRolModulo" hidden=true>
<mat-tree [dataSource]="treeDataModulo" [treeControl]="treeControl" class="example-tree bg-app-bar ">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
<!-- <ng-container *ngIf="node.activo=='1'"> -->

    <mat-checkbox class="mr-2"
                  color="primary"
                  [checked]="isSelectedNode(node)"
                  (change)="todoLeafItemSelectionToggle(node)"
                  [disabled]="true"
    >{{node.nombreModulo}} </mat-checkbox>

  <!-- </ng-container> -->
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <!-- <ng-container *ngIf="node.activo=='1'"> -->

    <div class="mat-tree-node">
      <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle'">
        <mat-icon class="mat-icon-rtl-mirror text-gray" *ngIf="treeControl.isExpanded(node)"  [icIcon]="icExpandMore"></mat-icon>
        <mat-icon class="mat-icon-rtl-mirror text-gray" *ngIf="!treeControl.isExpanded(node)" [icIcon]="icChevronRight"></mat-icon>
      </button>
      <mat-checkbox class="mr-2"
                    color="primary"
                    [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)"
                    [disabled]="true"
      > {{node.nombreModulo}}
      </mat-checkbox>
    </div>
    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
    </div>

  <!-- </ng-container> -->
  
  </mat-nested-tree-node>
</mat-tree>
