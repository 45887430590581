<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" class="truncate">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf=!photoUser [icIcon]="icPerson"></mat-icon>
        <!-- solo para demo  -->
        <img *ngIf=photoUser [src]=photoUser class="avatar">    
      </div>
      <div class="truncate">
        <div class="dropdown-heading truncate">{{currentUserName}}</div>
        <div class="dropdown-subheading truncate">{{currentUserEmail}}</div>
      </div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu"
            mat-icon-button
            matTooltip="Configuración"
            matTooltipPosition="before"
            type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content p-2">
  <!-- <div class="p-2"> -->
    <p class="font-medium text-primary">Razón Social</p>
    <mat-form-field class="w-full">
      <mat-select [(ngModel)]="currentSoc" (selectionChange)="onChangeSociedad($event)">
        <mat-option *ngFor="let entitie of entities" [value]="entitie.entitie">
          {{ entitie.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p class="font-medium text-primary">Estructura Organizativa</p>
    <vex-modulos-est-org class="pb-6" [treeDataModulo]=data *ngIf="data; else noData"> </vex-modulos-est-org>
    <p class="font-medium text-primary">Ejercicio</p>
    <button [matMenuTriggerFor]="yearMenu" class="dropdown-footer-select w-full" mat-button type="button">
      <ng-container *ngFor="let year of years; trackBy: trackById">
        <span *ngIf="year === activeYear" fxLayoutAlign="space-between center">
          <span> {{ year }}</span>
          <mat-icon [icIcon]="icArrowDropDown" class="ml-4 dropdown-footer-select-caret"></mat-icon>
          <!-- <mat-icon [icIcon]="icArrowDropDown"></mat-icon> -->
        </span>
      </ng-container>
    </button>
  </div> 
  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="end center">
    <a (click)="logout()" [routerLink]="['/login']" color="primary" mat-raised-button>Cerrar Sesión</a>
  </div>
</div>

<mat-menu #yearMenu="matMenu" xPosition="before" yPosition="above">
  <div gdColumns="1fr 1fr 1fr 1fr" gdGap="2px">
    <button (click)="setYear(year)"
            *ngFor="let year of years; trackBy: trackById"
            mat-menu-item>
      <span>{{ year }}</span>
    </button>
  </div>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <!-- <button mat-menu-item (click)="onChangePerfilData()">
    <mat-icon [icIcon]="icAccountCircle"></mat-icon>
    <span>Mi Perfil</span>
  </button> -->
  <button mat-menu-item (click)="onChangePerfilData()">
    <mat-icon [icIcon]="icAccountCircle"></mat-icon>
    <span>Configurar Foto</span>
  </button>
  <button mat-menu-item (click)="goChangePassword()">
    <mat-icon [icIcon]="icLock"></mat-icon>
    <span>Cambiar Contraseña</span>
  </button>
</mat-menu>

<ng-template #noData>  
  <p class="text-secondary pt-6">No tiene estructuras organizativas asignadas. </p>
  <p class="text-secondary pb-6">Comunicarse con el Administrador.</p>
</ng-template>
