  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline text-accent  m-0"
        fxFlex="auto">{{this.defaults.imageTitle}}</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <!-- show image -->
    <div *ngIf="!previewLogo" class="card my-4" fxLayoutAlign="center center" style="height:200px; width:300px;" >
      <p class="text-gray" *ngIf="!logoFile">Cargando ... </p>
      <img *ngIf="logoFile" src="{{logoFile}}" style="width: 150px; height:auto;" class="py-2">
    </div>
    <!-- show preview new image -->
    <div *ngIf="previewLogo" class="card my-4" fxLayoutAlign="center center" style="height:200px; width:300px;" >
      <img src="{{previewLogo}}" style="width: 150px; height:auto;" class="py-2">
    </div>
  <!-- upload image -->
    <div class="card mb-4">
        <input id="file-upload" (change)="getFile($event)" type="file" />
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button fxLayoutAlign="center center"
      color="primary" mat-raised-button (click)="uploadFile()" [disabled]="previewLogo==''">
      {{(loading) ? 'Cargando . . .' : 'Acepta subir imagen'}}
    </button>
    <button mat-stroked-button mat-dialog-close type="button">Cancelar</button>
  </mat-dialog-actions>
<!-- </form> -->
