import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[AuthService]
})
export class AppComponent {
  title = 'vex';
  isAuthenticated = false;
  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private router: Router,
              private authService: AuthService,
              private appstatusService: AppstatusService
              ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    // log: configurar nombre de app e imagen del logo
    this.configService.updateConfig({
      sidenav: {
        title: 'iCompliance',
        imageUrl: '/assets/img/_c-logo.png',
        showCollapsePin: true
      },
      // showConfigButton: false,
      footer: {
        visible: false
      }
    });
    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));
    
    this.appstatusService._chooseEntitie(this.appstatusService.getDataDefault(),this.appstatusService.getIdUserDefault());
    // this.appstatusService._setDataCurrentUserName();
    // _setDataCurrentUserPhoto
  }

  ngOnInit() {
    let that = this;
    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        if (authenticated) {
          var search=window.location.search;
          var pathname=window.location.pathname;
          that.router.navigate([(pathname||'/apps/contacts/grid/')+search]);
          
        } else {
          this.router.navigate(['/login']);
        }
      }
    );
    this.authService.initAuth();

    if(this.authService.getAuthenticatedUser()){
        this.authService.getAuthenticatedUser().getSession((err,session)=>{
          // console.info(session);
          // let a= fetch('https://eqw5ty3rg2.execute-api.us-east-2.amazonaws.com/dev/menu')
              // the JSON body is taken from the response
              // .then(res => res.json())
              // .then(response => {
                // The response has an `any` type, so we need to cast
                // it to the `User` type, and return it from the promise
                // that.router.navigate(['/']);
                // console.info("Menu");
                
                // console.info(response);
                // console.info("Menu");

                // that.navigationService.items=response;
                // that.navigationService.items.push();
                      
              // });
            });
      }
  }

  onLogout() {
    this.authService.logout();
  }
  ngOnDestroy() {
    this.authService.logout();
  } 
}


