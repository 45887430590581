import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametroService {

  constructor(private http: HttpClient) {  }

  saveParametro(dataParametro: any) { 
    const parameter = parseInt(localStorage.getItem('parameter'));
    const req = { 
      action:'save', 
      payload: {
        idParametro: parameter,
        detalle: dataParametro
      }
    };
    return this.http.post(environment.parametroURL, req);
  }

  deleteParametro(id: number) { 
    const req = {action:'delete', payload: {idParametro: id}};
    return this.http.post(environment.parametroURL, req);
  }
}
