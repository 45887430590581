import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Body } from 'src/app/interfaces/other.model';
import { NotifierService } from '../notifier/notifier.service';

interface Storage {
  size: number,
  total_storage: number
}

@Injectable({
  providedIn: 'root'
})
export class ModuloService {
  private modulosByParent = new BehaviorSubject([]);
  currentModulosByParent= this.modulosByParent.asObservable();
  private consumedStorage: BehaviorSubject<Storage> = new BehaviorSubject({size: 0, total_storage:0});
  currentconsumedStorage= this.consumedStorage.asObservable();

  moduloURL = environment.moduloURL;
  moduloTreeURL = environment.moduloTreeURL;

  constructor(private http: HttpClient,
              private notifierService: NotifierService) {  }

  getModulos() {
    const body = {action: 'findall'}
    return this.http.post(this.moduloURL,body);
  }

  getModulo(id: number) {
    const body = {action: 'find', payload: {where:{idModulo:id}}};
    return this.http.post(this.moduloURL,body);
  }

// devuelve los modulos para los roles
  getModulosUser(rols: number[],entitie:number,reader:number) {
    const req = { roles : rols, entitie:entitie, reader:reader };
    return this.http.post(environment.modulosUserS,req);
  }
  deleteModulo(id: number) {
    const body = {action: 'delete', payload: { idModulo:id}};
    return this.http.post(this.moduloURL,body);
  }

  saveModulo(body: Body) {
    return this.http.post(this.moduloURL, body);

  }

  updateModulo(body: Body) { 
    body.action = 'save';
    return this.http.post(this.moduloURL, body);
  }

  getByPeso(orden: number){
    let req = {
      action: 'find',
      payload: {
        where: {
          orden: orden
        },
        order:{
          label: 'ASC'
        }
      }
    };
    return this.http.post(this.moduloURL, req);
  }

  getModulosByParent(idParent: number, reader: number, dataDefault: any, eoAssigned: any) {
    // const eoAssigned=JSON.parse(localStorage.getItem('eoAssigned'));
    const req = {
        idParent:Number(idParent) ,
        roles: dataDefault.roles,
        reader: reader,
        entitie: dataDefault.entitie,
        grade: dataDefault.grade,
        structures: dataDefault.structures,
        tipoGestion: dataDefault.gestionSST,
        eoAssigned: eoAssigned
    }
    return this.http.post(environment.modulosbyparent,req);
  }
  getConsumedStorage(fiscal: string, entitie: number) {
    const req = {
        prefix: fiscal,
        idEntity: entitie
    }
    return this.http.post(environment.consumedStorage,req);
  }

  changeCurrentModulosByParent(idParent:number, reader: number,dataDefault: any) {
    const eoAssigned=JSON.parse(localStorage.getItem('eoAssigned'));
    if (dataDefault.structures && eoAssigned.length) {
      this.modulosByParent.next([]);
      this.getModulosByParent(idParent,reader,dataDefault,eoAssigned ).subscribe(res => {
        if (res['status']==200) {
          this.modulosByParent.next(res['data']);
        } else {
          this.notifierService.simpleNotification('El servidor no responde o no tiene conexión','Cerrar',4000);
        }
      });
    }  else {
      this.notifierService.showNotification('No tiene Estructura Organizativa asignada, comunicarse con el Administrador. Si visualiza Estructura Organizativa en el menú superior lateral derecho debe seleccionar una Estructura Organizativa.','Verificar','success');
    }
  }

  changeCurrentConsumedStorage(dataDefault: any) {
    if (dataDefault.fiscal) {
      this.getConsumedStorage(dataDefault.fiscal,dataDefault.entitie).subscribe((res: Storage) => {
        this.consumedStorage.next(res);
      });
    } else {
      this.notifierService.simpleNotification('Razón social no especificada.','Cerrar',4000);
    }
  }
}
