<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">APP</h1>
  </a>
  <!-- mostrar logo de la empresa  -->
  <img id="imageLogo" [src]=dataDefault.urlLogo [fxHide]="mobileQuery">
  <div class="truncate" translate="no" lang="es">
    <div class="text-secondary  ml-1 truncate">
      <span class="font-medium">{{ dataDefault.year}} - {{ dataDefault.sociedad }} - </span>
      <span [ngClass]="dataDefault.nameEstOrg.length == 1 ? 'text-cyan': 'text-gray'" >{{ dataDefault.nameEstOrg[0] }}</span>
      <span *ngIf="dataDefault.nameEstOrg[1]">\</span>
      <span [ngClass]="dataDefault.nameEstOrg.length == 2 ? 'text-purple': 'text-gray'" >{{ dataDefault.nameEstOrg[1] }}</span>
      <span *ngIf="dataDefault.nameEstOrg[2]">\</span>
      <span class="text-orange">{{ dataDefault.nameEstOrg[2] }}</span>
    </div>
    <div class="text-secondary ml-1 truncate">Mod: {{ dataDefault.modalidadTrabajo }} - {{ dataDefault.nombreRol }}</div>
  </div>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span fxFlex></span>  
  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon [icIcon]="icSearch"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user [currentUser]="dataDefault.userName" [photoUser]="photoUser"></vex-toolbar-user>
    </div>

  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
