import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagenService {

  constructor() {  }

  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })
  
  encodeImageFileAsURL(fileToLoad) {
    let srcData: any;
    let fileReader = new FileReader();
    
    fileReader.onload = function(fileLoadedEvent) {
      srcData = fileLoadedEvent.target.result; // <--- data: base64
      console.log("Converted Base64 version is ", srcData);
      return srcData;
    }
    fileReader.readAsDataURL(fileToLoad);
  }
}
