<div class="pb-6" fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button 
          color="primary" 
          [disabled] = isUpdating
          (click)="assignModulosToRol()">Asignar Estructura(s) Organizativa(s)</button>
</div>
<!-- <p class="p-12">Cargando Estructuras Organizativas . . .</p> -->
<!-- <p> {{checklistSelection.selected | json}}</p> -->
<mat-tree [dataSource]="treeDataModulo" [treeControl]="treeControl" class="example-tree bg-app-bar">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle >
    <mat-checkbox class="mr-2"
            color="primary"
            (change)="$event ? checklistSelection.toggle(node) : null"
            (click)="$event.stopPropagation()"
            [checked]="isSelectedNode(node)"
    ></mat-checkbox>
    <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
    <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
    <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
    {{ node.sociedad || node.grupoCentros || node.centro}}
  </mat-tree-node>
  <!-- [checked]="checklistSelection.isSelected(node)" -->

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node" >
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle'">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="treeControl.isExpanded(node)"  [icIcon]="icExpandMore"></mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="!treeControl.isExpanded(node)" [icIcon]="icChevronRight"></mat-icon>
      </button>
      <mat-checkbox class="mr-2"
            color="primary"
            (change)="$event ? checklistSelection.toggle(node) : null"
            (click)="$event.stopPropagation()"
            [checked]="isSelectedNode(node)"
      > </mat-checkbox>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
      <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
      {{ node.sociedad || node.grupoCentros || node.centro }} 
    </div>
    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>