import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NotifierComponent } from 'src/app/pages/shared/notifier/notifier.component';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  constructor(private snackBar: MatSnackBar) { }
  simpleNotification(message: string, buttonText: string, showTime: number){
    this.snackBar.open(message, buttonText, {
      duration: showTime,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    })
  }

  showNotification(message: string, buttonText: string, typeSnack: 'error' | 'success'){
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
        buttonText: buttonText,
        typeSnack: typeSnack
      },
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: typeSnack
    })
  }
}
