import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { ModuloService } from 'src/app/services/modulo/modulo.service';
import { ParametroService } from 'src/app/services/parametro/parametro.service';
import { menuAdmin, menuUser } from 'src/static-data/menu-data';
import { LogoService } from 'src/app/services/logo/logo.service';
import { S3Service } from 'src/app/services/s3.service';
import { BehaviorSubject } from 'rxjs';
import { arrModalidadTrabajo } from 'src/static-data/modalidad';

@Injectable({
  providedIn: 'root'
})
export class AppstatusService {
  arrModalidad = arrModalidadTrabajo;
  private photoUser$  = new BehaviorSubject<string>('');
  showUploadFilesButton$ = new BehaviorSubject(false);

  constructor(  private navigationService: NavigationService,
                private moduloService: ModuloService,
                private parametroService: ParametroService,
                private s3Service: S3Service,
                private logoService: LogoService,
                private activatedRoute: ActivatedRoute,
                private router: Router ) { 
  }

  // devuelve grupo economico activo
  getIdGrupoEconomicoDefault(): number{
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      return allEntitiesUser.group;
    }
  }
  // devuelve entidad activa
  getIdSociedadDefault(): number {
    const dataDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
    if (dataDefault) {
      return dataDefault.entitie;
    }
  }
  // devuelve nombre de entidad activa
  getNameEntitieDefault(): string {
    const dataDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
    if (dataDefault) {
      return dataDefault.name;
    }
  }
  // devuelte el id de la sociedad activa
  getDataDefault() {
    const dataDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
    if (dataDefault) {
      return dataDefault;
    }
    return null;
  }
  // devuelve usuario activo
  getIdUserDefault(): number {
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      return allEntitiesUser.reader;
    }    
  }
  // devuelve id usuario y url foto
  getIdUserPhoto(): any {
    let userData= {};
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      userData =  { reader: allEntitiesUser.reader , photo: allEntitiesUser.photo } ;
    }
    return userData;
  }
  // devuelve todas las entidades habilitadas para este usuario
  getEntitiesEnabled() {
    let arrayIdEntities: number[] = [];
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      allEntitiesUser.entities.forEach((element) => {
        if (element.header) {
          arrayIdEntities.push(element.entitie);
        }
      });    
    }  
    return arrayIdEntities;
  }
  // devuelve las entidades
  getMenuEntities() {
    let arrayEntities = [];
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      arrayEntities = allEntitiesUser.entities;
    }
    return arrayEntities;
  }
  // calcula year
  getYears(){
    const dataDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
    let arrayYear: number[] = [];
    if (dataDefault) {
      const endYear: number =  new Date().getFullYear();
      let iniYear: number = new Date(dataDefault.start).getFullYear();
      iniYear = (iniYear < 2012) ? 2012 : iniYear ;
      for (let year=iniYear; year <= endYear; year++){
        arrayYear.push(year);
      }
   }
    return arrayYear;
  }

  getActiveYear(){
    let setYear: number;
    const dataDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
    if (dataDefault) {
      setYear = dataDefault.grade;
    }
    return setYear;
  }
  getDataEntitie(id: number) {
    const allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let dataEntitie= [];
    if (allEntitiesUser) {
      dataEntitie = allEntitiesUser.entities.filter(entitie => entitie.entitie == id)
    }
    return dataEntitie[0];
  }
  // devuelve datos del modulo actual
  _getCurrentModuleData() {
    const currentModuleData = JSON.parse(localStorage.getItem('currentModuleData'));
    if (currentModuleData) {
      return currentModuleData   
    }
  }

  // devuelve id de todos los centros asignados
  _getIdCentrosAssigned(idGrupoCentroToFilter?: number){
    const eoAssigned=JSON.parse(localStorage.getItem('eoAssigned'));
    let arrayCentros = [];
    if (eoAssigned) {
      arrayCentros = idGrupoCentroToFilter 
                    ? eoAssigned.filter(entitie => entitie.tipoEstOrg == 3 && entitie.idParent == idGrupoCentroToFilter)
                    : eoAssigned.filter(entitie => entitie.tipoEstOrg == 3);
    }
    return arrayCentros;
  }
  // establece usuario activo
  _setDataCurrentUserName() {
    const allEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (allEntitiesUser) {
      this.navigationService.entitie.userName = allEntitiesUser.user;
      this._setDataCurrentUserPhoto(allEntitiesUser.userUrl);
    } 
  }

  _setDataCurrentUserPhoto(userUrl: string) {
    if (userUrl) {
      let {bucket, nombreArchivo} = this.dataBucketFilename(userUrl);
      this.s3Service.download(bucket, nombreArchivo).subscribe(resp => {
        let link = resp['data'].url
        this.navigationService.entitie.userPhoto = link;
        this.navigationService.triggerLoadPhoto(link);
      });
    } else {
      this.navigationService.entitie.userPhoto = '';
    }
  }

  setYearDefault(newYear: number){
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let idxEntitie = allEntitiesUser.findIndex(entitie => entitie.idSociedad == dataDefault.entitie.idSociedad )
    if (idxEntitie != -1) {
      allEntitiesUser[idxEntitie].activeYear = newYear;
      dataDefault.entitie.activeYear = newYear;
    }
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    this.navigationService.entitie.year = newYear;
    this.router.navigate(['/']);
  }
  // asignar modulo por defecto
  //  setModuloDefault(data: any){
  //   console.log(data);
  //   let dataModulo = JSON.parse(localStorage.getItem('dataModulo'));
  //   if (dataModulo) {
  //     dataModulo = data;
  //   }
  //   localStorage.setItem("dataModulo",JSON.stringify(dataModulo));
  // }
  // cambio de opciones menu lateral
  chooseSideNav(entitie: any, reader: number) {
    this.navigationService.items.splice(0);
    if (entitie.header){
      menuAdmin.forEach(data => this.navigationService.items.push(data));
      if (entitie.nameRols.length > 1) {
        this.setMenuModulosUser(entitie.roles,entitie.entitie,reader);
      }
    } else {
      this.setMenuModulosUser(entitie.roles,entitie.entitie,reader);
    }
  }

  // asignar datos del usuario
  _setAllEntities(entitiesUser: any) {
    if (entitiesUser) {
      let objEntitiesUser = JSON.parse(entitiesUser.detalle);
      let objEntities = objEntitiesUser.entities;
      // inicio de implementar base64 de los logos
      objEntities.forEach(entitie => {
        // console.log(entitie.url);
        entitie.img='assets/img/compliance.jpg';
        if (entitie.url) {
          let {bucket, nombreArchivo} = this.dataBucketFilename(entitie.url);
          this.s3Service.download(bucket, nombreArchivo).subscribe(resp => {
            // console.log(resp);
            let link = resp['data'].url
            entitie.img=link;
            // this.navigationService.entitie.urlLogo = link;
            // this.logoService.getLogo(link).subscribe(resFile=> {
            //   console.log(resFile);
            //   this.encodeImageFileAsURL(resFile);
            // });
          });
        }
      });
      objEntitiesUser.entities=objEntities;
      // fin de implementar base64 de los logos
      if (entitiesUser) {
        const AllEntitiesUser=JSON.parse(localStorage.getItem('AllEntitiesUser'));
        if (!AllEntitiesUser) {
          localStorage.setItem("AllEntitiesUser",entitiesUser.detalle);
          // this._setDataCurrentUserName();
          // this._setDataCurrentUserPhoto(objEntitiesUser.userUrl);
        }
        const parameter=JSON.parse(localStorage.getItem('parameter'));
        if (!parameter) {
          localStorage.setItem("parameter",entitiesUser.idParametro);
        }
        const eoAssigned=JSON.parse(localStorage.getItem('eoAssigned'));
        if (!eoAssigned) {
          localStorage.setItem("eoAssigned",JSON.stringify(entitiesUser.eoAsignadas));
        }
        let objDataUserDefault = JSON.parse(entitiesUser.detalle).entities[0];
        const dataUserDefault=JSON.parse(localStorage.getItem('dataUserDefault'));
        if (!dataUserDefault) {
          localStorage.setItem("dataUserDefault",JSON.stringify(objDataUserDefault));
        }
        this._chooseEntitie(objDataUserDefault,this.getIdUserDefault());
      }
    }
  }
  
  _chooseEntitie(entitie: any,reader:number){
    if (entitie) {
      this.navigationService.entitie.sociedad = entitie.name;
      this.navigationService.entitie.nombreRol = entitie.nameRols.length ? entitie.nameRols : 'No tiene roles asignados';
      this.navigationService.entitie.year = entitie.grade;
      this.navigationService.entitie.estOrg = entitie.structureName;
      this.navigationService.entitie.nameEstOrg = entitie.structureName.split('\\');
      this.navigationService.entitie.modalidadTrabajo = entitie.modality;
      if (entitie.url) {
        let {bucket, nombreArchivo} = this.dataBucketFilename(entitie.url);
        this.s3Service.download(bucket, nombreArchivo).subscribe(resp => {
          this._setUrlinNavigation(resp['data'].url);
        });
      } else {
        this.navigationService.entitie.urlLogo = 'assets/img/noImage.png';
      }
      this._setDataCurrentUserName();
      this.chooseSideNav(entitie,reader);
    }
  }
  _setUrlinNavigation(urlNavigation: string) {
    this.navigationService.entitie.urlLogo = urlNavigation;
  }
  // modifica el año en la informacion default y general
  _setYearDefault(newYear: number){
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let idxEntitie = allEntitiesUser.entities.findIndex(element => element.entitie == dataDefault.entitie )
    if (idxEntitie != -1) {
      allEntitiesUser.entities[idxEntitie].grade = newYear;
    }
    dataDefault.grade = newYear;
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    this.navigationService.entitie.year = newYear;
    this.saveParametro();
    this._setDataModulesShow(Number(localStorage.getItem("idModuleParent")));
  }
  setImgDefault(newUrl: string, idEntitie: number){
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let idxEntitie = allEntitiesUser.entities.findIndex(element => element.entitie == idEntitie )
    if (idxEntitie != -1) {
      allEntitiesUser.entities[idxEntitie].url = newUrl;
      if (idEntitie == dataDefault.entitie) {
        dataDefault.url = newUrl;
      }
    }
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    // this.navigationService.entitie.urlLogo = newUrl;
    // this.router.navigate(['/']);
    this.saveParametro();

  }
  setPhotoDefault(newUrl: string){
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    if (newUrl) {
      allEntitiesUser.photo = newUrl;
    }
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    this.saveParametro();
  }

  _setDataDefault(idItem: number){
    const allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    let idxEntitie = allEntitiesUser.entities.findIndex(entitie => entitie.entitie == idItem );
    if (idxEntitie != -1) {
      dataDefault = allEntitiesUser.entities[idxEntitie];
    }
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    this._chooseEntitie(dataDefault,allEntitiesUser.reader);
    this.router.navigate(['/']);
  }

  setStructure(structures: any,structureName: string) {
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    dataDefault.structures = structures;
    dataDefault.structureName = structureName;
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    this.navigationService.entitie.estOrg = structureName;
    this.navigationService.entitie.nameEstOrg = structureName.split('\\');

    // actualizar datos generales
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let idxEntitie = allEntitiesUser.entities.findIndex(entitie => entitie.entitie == dataDefault.entitie);
    if (idxEntitie != -1) {
      allEntitiesUser.entities[idxEntitie] = dataDefault;
    }
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    this.saveParametro();
    let newIdModuleParent =  Number(localStorage.getItem("idModuleParent"));
    newIdModuleParent = newIdModuleParent  ? newIdModuleParent  : 16;
    this._setDataModulesShow(newIdModuleParent);
  }
  // asignar menu lateral de acuerdo al usuario
  setMenuModulosUser(roles: number[],entitie: number,reader:number) {
    this.moduloService.getModulosUser(roles,entitie,reader).subscribe(resModulo=>{
      // console.log(resModulo);
      if (resModulo['status']==200) {
        menuUser[0]['children'].splice(0);
        resModulo['data'].childrenRoot.forEach(menu => menuUser[0]['children'].push(menu));
        menuUser.forEach(data => this.navigationService.items.push(data));
      }
    });
  }

  saveParametro() {
    const allEntitiesUser=localStorage.getItem('AllEntitiesUser');
    if (allEntitiesUser) {
      this.parametroService.saveParametro(allEntitiesUser).subscribe();
    }
  }

  clearStorage(){
    try {
      localStorage.clear();
    } catch(error) {
      console.log('No se ha completado proceso logout', error );
    }
  }
  _setName(obj: any) {
    this.navigationService.entitie.sociedad = obj.sociedad;
    this.navigationService.entitie.modalidadTrabajo = this.arrModalidad.find(elementMod => elementMod.tipoModalidad == obj.tipoModalidadTrabajo).nombreModalidad;
    let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
    let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
    let idxEntitie = allEntitiesUser.entities.findIndex(element => element.entitie == obj.idSociedad )
    if (idxEntitie != -1) {
      allEntitiesUser.entities[idxEntitie].name = obj.sociedad;
      allEntitiesUser.entities[idxEntitie].place = obj.domicilioFiscal;
      allEntitiesUser.entities[idxEntitie].prefix = obj.prefijo;
      allEntitiesUser.entities[idxEntitie].fiscal = obj.idFiscal;
      allEntitiesUser.entities[idxEntitie].modality = this.navigationService.entitie.modalidadTrabajo;
      if (allEntitiesUser.entities[idxEntitie].structures.gc === null && allEntitiesUser.entities[idxEntitie].structures.c=== null) {
        allEntitiesUser.entities[idxEntitie].structureName = obj.sociedad;
      }
      if (obj.idSociedad == dataDefault.entitie) {
        dataDefault.name = obj.sociedad;
        dataDefault.place = obj.domicilioFiscal;
        dataDefault.prefix = obj.prefijo;
        dataDefault.fiscal = obj.idFiscal;
        dataDefault.modality = this.navigationService.entitie.modalidadTrabajo;
        if (dataDefault.structures.gc === null && dataDefault.structures.c=== null) {
          dataDefault.structureName = obj.sociedad;
          this.navigationService.entitie.estOrg = obj.sociedad;
          this.navigationService.entitie.nameEstOrg = [obj.sociedad];
        }
      }
    }
    localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
    localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
    // this.router.navigate(['/']);
    this.saveParametro();
  }
  // Modifica solo modalidad
  _setModality(textModality: string) {
    if (textModality && this.navigationService.entitie.modalidadTrabajo !== textModality ) {
      const currentEntitie = this.getIdSociedadDefault();
      let dataDefault = JSON.parse(localStorage.getItem('dataUserDefault'));
      let allEntitiesUser = JSON.parse(localStorage.getItem('AllEntitiesUser'));
      let idxEntitie = allEntitiesUser.entities.findIndex(element => element.entitie == currentEntitie);
      this.navigationService.entitie.modalidadTrabajo = textModality;
      if (idxEntitie != -1) {
        allEntitiesUser.entities[idxEntitie].modality = textModality;
        if (currentEntitie == dataDefault.entitie) {
          dataDefault.modality = textModality;
        }
      }
      localStorage.setItem("AllEntitiesUser",JSON.stringify(allEntitiesUser));
      localStorage.setItem("dataUserDefault",JSON.stringify(dataDefault));
      this.saveParametro();
    }
  }

  // Asigna el texto a la barra breadcrumb
  _setCurrentCrumb(newCrumb: string[]){
    // this.navigationService.arrCrumbs=newCrumb;
  }
  // Asigna informacion de los modulos mostrados
  _setDataModulesShow(idParent: number) {
    this.moduloService.changeCurrentModulosByParent(idParent,this.getIdUserDefault(),this.getDataDefault());
  }
  _setStorageConsumed() {
    this.moduloService.changeCurrentConsumedStorage(this.getDataDefault());
  }
  // Asigna data del modulo actual
  _setChangeLabel(currentCrumb: string[], objModulo: any){
    // this.navigationService.setModuloDefault(objModulo);
    // this.navigationService.arrCrumbs=currentCrumb;
    // this.navigationService.arrCrumbs.push(objModulo.nombreModulo);
    // this.navigationService.arrCrumbs=[currentCrumb,objModulo.nombreModulo];
  }
  // Asigna datos del modulo actual
  // eliminar lineas desde 132
  _setCurrentModuleData(newDataModule:any) {
    localStorage.setItem("currentModuleData",JSON.stringify(newDataModule));
    this._setUploadFilesButton();
  }

_setUploadFilesButton() {
    const {structures} = this.getDataDefault();
    const newDataModule = this._getCurrentModuleData();
    const resCanEdit = newDataModule ? newDataModule.canEdit : false ;
    // if (newDataModule.canEdit) {
    if (resCanEdit) {
      if (newDataModule.currentLabelsModule.length == 1) {
        switch (newDataModule.currentLabelsModule[0].label) {
          case 'Sociedad' : this.showUploadFilesButton$.next(structures.gc === null && structures.c === null);
                            break;
          case 'Centro'   : this.showUploadFilesButton$.next(structures.c !== null);
                            break;
        }
      } else {
        this.showUploadFilesButton$.next(true);
      }
    }
  }
  // inicializa valores de nombre y menu
  _setClearCurrentName() {
    this.navigationService.entitie.userName= '';
    this.navigationService.items.splice(0);
  }

  // devuelve la lista de modulos
  _getDataModulesShow(){
    return this.moduloService.currentModulosByParent;
  }
  // devuelve almacenamiento usado
  _getConsumedStorage(){
    return this.moduloService.currentconsumedStorage;
  }

// devuelve nombre de usuario actual
  _getUserName() {
    return this.navigationService.entitie.userName;
  }

  _getUserPhoto() {
    return this.navigationService.entitie.userPhoto;
  }

  private encodeImageFileAsURL(fileToLoad) {
      var fileReader = new FileReader();
      fileReader.onload = function(fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
      }
      fileReader.readAsDataURL(fileToLoad);
    }
  
  private dataBucketFilename(fileRutaS3: string) {
    let ruta=fileRutaS3.split("/");
    let nombreArchivo=decodeURI(ruta.pop());
    let bucket=ruta.join("/");
    return {bucket, nombreArchivo};
  }
}
