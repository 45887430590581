import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icLock from '@iconify/icons-ic/twotone-lock';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { Router } from '@angular/router';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatDialog} from '@angular/material/dialog';

import { AuthService } from 'src/app/auth.service';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';
import { EntitieService } from 'src/app/services/entitie/entitie.service';
import { AdjuntosService } from 'src/app/services/adjuntos/adjuntos.service';
import { EventoService } from 'src/app/services/evento/evento.service';
import { ImageSettingComponent } from 'src/app/pages/shared/image-setting/image-setting.component';

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[AuthService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ToolbarUserDropdownComponent implements OnInit {

  years: number[] = this.appstatusService.getYears();
  activeYear = this.appstatusService.getActiveYear();
  entities = this.appstatusService.getMenuEntities();
  currentSoc = this.appstatusService.getIdSociedadDefault();
  currentUserPhoto = this.appstatusService.getIdUserPhoto();
  data:any;
  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icArrowDropDown = icArrowDropDown;
  icAccountCircle = icAccountCircle;
  icLock = icLock;
  currentUser={};
  currentUserName="";
  currentUserEmail="";
  /***************solo para demo */
  photoUser="";
  constructor(private authService: AuthService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private appstatusService: AppstatusService,
              private entitieService: EntitieService,
              private adjuntosService: AdjuntosService,
              private eventoService: EventoService,
              private dialog: MatDialog,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>
              ) {  
                 this.data = this.entitieService.getTreeSocietyAssigned(this.currentSoc,this.currentUserPhoto.reader);
              }

  ngOnInit() {
    var a=this.authService.getAuthenticatedUser();
    var that=this;
    this.authService.getAuthenticatedUser().getSession((err,session)=>{
          if(err){
            return;
          }else{
            // console.info(session.getIdToken().payload['cognito:username']);
            // console.info("Decode:",session.getIdToken().decodePayload());
            that.currentUserName=this.appstatusService._getUserName();
            // that.currentUserName=session.getIdToken().decodePayload().name;
            that.currentUserEmail=session.getIdToken().decodePayload().email;
            // console.log(session.getIdToken().decodePayload());
            // localStorage.setItem('lastAuthUser', session.getIdToken().decodePayload().email);
            // localStorage.setItem('accessToken', session.getIdToken().payload['cognito:username']);
            this.photoUser= this.appstatusService._getUserPhoto();
            /***************solo para demo */
            // switch (that.currentUserEmail) {
            //   case 'hponti@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/3.jpg'; break;
            //   case 'carana@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/19.jpg'; break;
            //   case 'bumeran@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/5.jpg'; break;
            //   case 'earce@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/2.jpg'; break;
            //   case 'rflores@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/1.jpg'; break;
            //   case 'clozano@yopmail.com': this.photoUser= 'https://vex.visurel.com/assets/img/avatars/14.jpg'; break;
            //   default: 
            //     break;
            // }
          }
    });
  }
  close(idItem: number) {
    this.popoverRef.close();
  }

  setYear(year: number) {
    this.popoverRef.close();
    this.appstatusService._setYearDefault(year);
    this.adjuntosService.changeCurrentList();
  }

  onChangeSociedad(event) {
    this.popoverRef.close();
    this.appstatusService._setDataDefault(event.value);
    this.data = this.entitieService.getTreeSocietyAssigned(event.value,this.appstatusService.getIdUserDefault());
  }

  onChangePerfilData() {
    this.popoverRef.close();
    this.dialog.open(ImageSettingComponent,{
      data: {
        idEntitie: this.currentUserPhoto.reader,
        url: this.currentUserPhoto.photo,
        imageTitle: 'Configurar Foto de Usuario',
        isLogo: false
      }
    })
    .afterClosed().subscribe(dataPhoto => {
        if (dataPhoto) {
          this.photoUser = dataPhoto;
          console.log('good');
        }
    });
    
  }

  goChangePassword() {
    const usrName = localStorage.getItem('CognitoIdentityServiceProvider.lastAuthUser');
    this.logout();
    let response= this.authService.forgotPassword(usrName.split("@")[0]+"_"+usrName.split("@")[1]);
    this.router.navigate(['/change-password',{"usuario":usrName}]);
  }

  logout(){
    this.authService.logout();
    this.close(0);
    this.eventoService.saveEvento(this.currentSoc,'Cerró Sesión').subscribe();
    this.appstatusService.clearStorage();
  }
}
