import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InjectSessionInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const idTokenUser = localStorage.getItem('CognitoIdentityServiceProvider.idToken');
    let headers = new HttpHeaders();
    let newRequest = request;

    // if (idTokenUser ) {
    //   newRequest = request.clone({
    //     headers: request.headers.set('Authorization', idTokenUser)
    //   });

    // }

    // newRequest = request.clone({
    //   headers = headers.set('Authorization', localStorage.getItem('CognitoIdentityServiceProvider.idToken'),);
    // })
    
    // console.log('req', request);
    return next.handle(request);
  }
}
