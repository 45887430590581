import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';

@Injectable({
  providedIn: 'root'
})
export class RolModuloService {

  rolModuloURL = environment.rolModuloURL;
  modulosByRolURL =  environment.modulosByRolURL;
  // activeModulURL =environment.activeModulURL;

  constructor(private http: HttpClient,
              private appstatusService: AppstatusService) {  }
  
  getModulosByRol(idRol:number, idSociedad:string) {
    const body = {
          idRol: idRol,
          idSociedad: idSociedad
    };
    return this.http.post(this.modulosByRolURL,body);
  }

  getActiveModul(idRol:number, idSociedad:string) {
    const body = {
          idRol: idRol,
          idSociedad: idSociedad
    };
    return this.http.post(environment.activeModulURL,body);
  }
  getModulosAcciones(idRol:number, userId: number, idSociedad:string) {
    const body = {
          idRol: idRol,
          idSociedad: idSociedad,
          entitie: this.appstatusService.getIdSociedadDefault(),
          reader:  userId
    };
    return this.http.post(environment.modulosAccion,body);
  }
  getModulosForRol(idRol:number) {
    const body = {action: 'find',
      payload: {
        where: {
          idRol: idRol,
          idSociedad: '*'
        },
        relations: ['idRol','idModulo']
      }
    };
    return this.http.post(this.rolModuloURL,body);
  }

  saveRolModulo(body) {
    // const url = this.setUrl(bodyentitie.action);
    // body.action = 'save';
    return this.http.post(this.rolModuloURL, body);

  }

  updateRolModulo(body: Body) { 
    // const url = this.setUrl(bodyentitie.action);
    // body.action = 'save';
    return this.http.post(this.rolModuloURL, body);
  }

  deleteRolModulo(id: number) {
    const body = {action: 'delete', payload: { id:id}};
    console.log(this.rolModuloURL);
    return this.http.post(this.rolModuloURL,body);
  }
}
