import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  constructor(
    private http: HttpClient,
    ) {  }

  getEventos() {
    const currentEntitie = JSON.parse(localStorage.getItem('dataUserDefault')).entitie;
    const body = {  
      action: 'find', 
      payload: {
        where : {
          idSociedad     : currentEntitie
        },
        order : {
          fechaCreacion : "DESC"
        }
      }
    };
    return this.http.post(environment.eventoURL,body);
  }

  getEvento(id: number) {
    const body = {action: 'find', payload: {where:{idEvento:id}}};
    return this.http.post(environment.eventoURL,body);
  }

  saveEvento(idSociedad: number, detalleEvento: string) {
    let currentUser   = localStorage.getItem('CognitoIdentityServiceProvider.lastUserName');
    let currentEmail  = localStorage.getItem('CognitoIdentityServiceProvider.lastAuthUser');
    const body =  { action: 'save', 
                    payload: {
                      idSociedad: idSociedad,
                      correoElectronico: currentEmail,
                      nombreUsuario: currentUser,
                      descripcionEvento: detalleEvento,
                      fechaEvento: new Date(),
                    }
                  };
    return this.http.post(environment.eventoURL, body);
  }

}
