import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpEventType } from '@angular/common/http';

import icClose from '@iconify/icons-ic/twotone-close';
import { S3Service } from 'src/app/services/s3.service'

import { EntitieService } from 'src/app/services/entitie/entitie.service';
import { UserService } from 'src/app/services/user/user.service';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';
import { ImagenService } from 'src/app/services/imagen/imagen.service';

@Component({
  selector: 'vex-image-setting',
  templateUrl: './image-setting.component.html'
})
export class ImageSettingComponent implements OnInit {

  // logoFormGroup: FormGroup;
  mode: 'create' | 'update' = 'create';
  // showButtonUpload: boolean = false;
  logoFile: string ='';
  fileForUpload: any;
  previewLogo: string = '';
  loading: boolean;
  icClose = icClose;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<ImageSettingComponent>,
              private entitieService: EntitieService,
              private userService: UserService,
              private s3service: S3Service,
              private appstatusService: AppstatusService,
              private imagenService: ImagenService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    // console.log(this.defaults);
    this.showLogo(this.defaults.url);
  }

  showLogo(fileRutaS3: string) {
    // console.log('mostrar logo ', fileRutaS3);
    if (fileRutaS3) {
      // let ruta=fileRutaS3.split("/");
      // let nombreArchivo=decodeURI(ruta.pop());
      // let bucket=ruta.join("/");
      let {bucket, nombreArchivo} = this.dataBucketFilename(fileRutaS3);
      this.downloadFile(bucket,nombreArchivo);
    } else {
      this.logoFile = (this.defaults.isLogo) ? "assets/img/noImage.png" : "assets/img/noPhoto.png";
    }
  } 

  downloadFile(bucket: string, name: string) {
    //BASE DE DATOS - OBTENER INFO
    this.s3service.download(bucket, name).subscribe(resp => {
      this.logoFile = resp['data'].url;
      // this.appstatusService._setUrlinNavigation(resp['data'].url);
    });
  }

  getFile(event): any {
    this.fileForUpload = event.target.files[0];
    this.imagenService.extraerBase64(this.fileForUpload).then((imagen: any) => {
      this.previewLogo = imagen.base;
    })
  }

  uploadFile() {
    if (this.defaults.isLogo) {
      this.uploadFileLogo();
    } else {
      this.uploadFilePhoto();
    }
  }

  uploadFileLogo() {
    const dataEntitie = this.appstatusService.getDataEntitie(this.defaults.idEntitie);
    this.loading = true;
    const file = this.fileForUpload;
    const bucket = "/" + dataEntitie.fiscal+ "/logo";
    this.s3service.uploadFile(file, file.type, bucket, false).subscribe(res => {
      let url = res['data'].url;
      this.s3service.url(url, file).subscribe(res => {
        if (res['type'] === HttpEventType.Response) {
          const newUrlLogo= this.routeFile(res.url);
          this.entitieService.updateLogoEntitie({
              idSociedad: this.defaults.idEntitie,
              urlLogo: newUrlLogo,
          }).subscribe(resp => { 
              if (resp['status']==200) {
                this.appstatusService.setImgDefault(newUrlLogo,this.defaults.idEntitie);
                if (this.defaults.idEntitie == this.appstatusService.getIdSociedadDefault()) {
                  this.appstatusService._setUrlinNavigation(this.previewLogo);
                }
                this.dialogRef.close('ok');
              }
            });
        } else
          if (res['type'] === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * res['loaded'] / res['total']);
          }
      })
    })
  }

  uploadFilePhoto() {
    const dataEntitie = this.appstatusService.getDataDefault();
    this.loading = true;
    const file = this.fileForUpload;
    const bucket = "/" + dataEntitie.fiscal+ "/foto-usuarios";
    this.s3service.uploadFile(file, file.type, bucket, false).subscribe(res => {
      let url = res['data'].url;
      this.s3service.url(url, file).subscribe(res => {
        if (res['type'] === HttpEventType.Response) {
          const newUrlPhoto= this.routeFile(res.url);
          this.userService.updateUserPhoto({
              id: this.defaults.idEntitie,
              foto: newUrlPhoto,
          }).subscribe(resp => { 
              if (resp['status']==200) {
                this.appstatusService.setPhotoDefault(newUrlPhoto);
                if (this.defaults.idEntitie == this.appstatusService.getIdSociedadDefault()) {
                  this.appstatusService._setUrlinNavigation(this.previewLogo);
                }
                this.dialogRef.close('ok');
              }
            });
        } else
          if (res['type'] === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * res['loaded'] / res['total']);
          }
      })
    })
  }

  private routeFile(urlbase: string) {
    let url=urlbase.split("?")[0].split("/");
    url.shift();
    url.shift();
    url.shift();
    return  url.join("/");
  }

  private dataBucketFilename(fileRutaS3: string) {
    let ruta=fileRutaS3.split("/");
    let nombreArchivo=decodeURI(ruta.pop());
    let bucket=ruta.join("/");
    return {bucket, nombreArchivo};
  }
}
