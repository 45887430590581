export const tenant = '8405931b-f2f7-475b-bca4-49ad5db6';
export interface grupoEconomico {
  idGrupoEconomico?: number;
  grupoEconomico: string;
  activo: string;
  inicioOperaciones: Date;
  finOperaciones?: Date | null;
  fechaCreacion?:  Date | null;
  fechaModificacion?:  Date | null;
  creadoPor?: number | null;
  modificadoPor?: number | null;
  idTenant?: string | null;
};

export interface sociedad {
  idSociedad?: number;
  sociedad: string;
  idGrupoEconomico?: number;
  activo: string;
  idFiscal: string;
  inicioOperaciones: Date;
  // finOperaciones: Date | null;
  razonSocial: string;
  domicilioFiscal: string;
  numeroLicencias: number;
  numContrato: string;
  fechaContrato: Date;
  // urlLogo: string;
  fechaCreacion?: Date | null;
  fechaModificacion?: Date | null;
  creadoPor?: number | null;
  modificadoPor?: number | null;
  idTenant?: string | null;
};

export interface grupoCentros {
  idGrupoCentros?: number;
  grupoCentros: string;
  idSociedad?: number;
  activo: string;
  inicioOperaciones: Date;
  // finOperaciones: Date | null;
  fechaCreacion?: Date | null;
  fechaModificacion?: Date | null;
  creadoPor?: number | null;
  modificadoPor?: number | null;
  idTenant?: string | null;
};

export interface centro {
  idCentro?: number;
  centro: string;
  idGrupoCentros?: number;
  activo: string;
  inicioOperaciones: Date;
  // finOperaciones?: Date | null;
  fechaCreacion?: Date | null;
  fechaModificacion?: Date | null;
  creadoPor?: number | null;
  modificadoPor?: number | null;
  idTenant?: string | null;
};

export interface bodyEntitie {
  action: string;
  payload?: grupoEconomico | sociedad | grupoCentros | centro;
}

export interface getgrupoEconomico {
  action: string;
  payload: {
      where: { 
          idGrupoEconomico: number;
      } 
  }
}

export interface getsociedad {
  action : string;
  payload: {
      where: { 
          idSociedad: number;
      } 
  }
}

export interface getgrupoCentros {
  action : string;
  payload: {
      where: { 
          idGrupoEconomico: number;
      } 
  }
}

export interface getcentro {
  action : string;
  payload: {
      where: { 
          idGrupoCentros: number;
      } 
  }
}

export interface Tree {
  mensaje: string;
  status: number;
  data: [
    {
      idGrupoEconomico?: number;
      grupoEconomico: string;
      activo: string;
      inicioOperaciones: Date;
      finOperaciones?: Date;
      fechaCreacion?: Date;
      fechaModificacion?: Date;
      creadoPor?: number;
      modificadoPor?: number;
      idTenant?: string;
      sstSociedads: [
        {
          idSociedad?: number;
          sociedad: string;
          activo: string;
          inicioOperaciones: Date;
          finOperaciones: Date;
          // idGrupoEconomico: number;
          fechaCreacion?: Date;
          fechaModificacion?: Date;
          creadoPor?: number;
          modificadoPor?: number;
          idTenant?: string;
          sstGrupoCentros: [
            {
              idGrupoCentros?: number;
              grupoCentros: string;
              // idSociedad: number;
              activo: string;
              // inicioOperaciones: Date;
              // finOperaciones: Date;
              fechaCreacion?: Date;
              fechaModificacion?: Date;
              creadoPor?: number;
              modificadoPor?: number;
              idTenant?: string;
              sstCentros: [
                {
                  idCentro?: number;
                  centro: string;
                  // idGrupoCentros: number;
                  activo: string;
                  inicioOperaciones: Date;
                  finOperaciones: Date;
                  fechaCreacion?: Date;
                  fechaModificacion?: Date;
                  creadoPor?: number;
                  modificadoPor?: number;
                  idTenant?: string;
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};



export interface resDataTree {
  idGrupoEconomico?: number;
  grupoEconomico?: string;
  idSociedad?: number;
  sociedad?: string;
  idGrupoCentros?: number;
  grupoCentros?: string;
  idCentro?: number;
  centro?: string;
  activo: string;
  urlLogo?: string;
  inicioOperaciones?: Date;
  finOperaciones?: Date;
  fechaCreacion?: string  | null;
  fechaModificacion?: string  | null;
  creadoPor?: number  | null;           // deberia ser string, el dato vino como 1 
  modificadoPor?: number  | null;
  idTenant?: string  | null;
  sstSociedads?: resDataTree[];
  sstGrupoCentros?: resDataTree[];
  sstCentros?: resDataTree[];
  asignado?: boolean;
  structureName?: string;
  structures?:[];
  isAssigned?:boolean;
  idParent?: number;
  gestionSst:string;
  tipoModalidadTrabajo: string;
  fechaModalidad?: Date;
}

export interface responseTree {
  mensaje: string;
  status: number;
  data: resDataTree[]; 
}
