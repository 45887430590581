import { Icon } from '@visurel/iconify-angular';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from 'src/@vex/interfaces/navigation-item.interface';
import icClass from '@iconify/icons-ic/class';
import icHomeWork from '@iconify/icons-ic/twotone-home-work';
import faHardHat from '@iconify/icons-fa-solid/hard-hat';
import icGroup from '@iconify/icons-ic/twotone-group';
import icGroups from '@iconify/icons-ic/twotone-groups';
import icAdminPanel from '@iconify/icons-ic/twotone-admin-panel-settings';
import icAddBusiness from '@iconify/icons-ic/twotone-add-business';
import icEventNote from '@iconify/icons-ic/twotone-event-note';
export const menuAdmin: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Administración',
    children: [
      {
        type: 'link',
        label: 'Estructuras Organizativas',
        route: '/admin/nodos',
        icon: icHomeWork   
      },
      {
        type: 'link',
        label: 'Roles',
        route: '/admin/roles',
        icon: icGroups   
      },
      {
        type: 'link',
        label: 'Usuarios',
        route: '/admin/users',
        icon: icGroup   
      },
      {
        type: 'link',
        label: 'Asignar Usuarios a Roles',
        route: '/admin/usersrol',
        icon: icAdminPanel   
      },
      {
        type: 'link',
        label: 'Asignar Usuarios a Estructuras Organizativas',
        route: '/admin/usersestorg',
        icon: icAddBusiness   
      },
      {
        type: 'link',
        label: 'Bitácora',
        route: '/admin/historial-de-eventos',
        icon: icEventNote   
      },
    ]
  }
  ];

export let menuUser: NavigationItem[] = [
    {
        type: 'subheading',
        label: 'SST - 👨‍🚒Seguridad y Salud',
        children: []
    },
    {
        type: 'subheading',
        label: 'MA - 🌳Medio Ambiente',
        children: []
    },
    {
        type: 'subheading',
        label: 'CA - ✅Calidad Total',
        children: []
    },
    {
        type: 'subheading',
        label: 'HS - 🚫Hostigamiento Sexual',
        children: []
    },
  ];