import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms'; 
import { IconModule } from '@visurel/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { MatTreeModule } from '@angular/material/tree';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModulosComponent } from './modulos.component';
import { ModulosEstOrgComponent } from './modulos-est-org/modulos-est-org.component';
import { ModulosForAssignComponent } from './modulos-for-assign/modulos-for-assign.component';

import { ModulosRoutingModule } from './modulos-routing.module';
import { ModuloService } from 'src/app/services/modulo/modulo.service';

@NgModule({
  declarations: [ModulosComponent,ModulosEstOrgComponent,ModulosForAssignComponent],
  imports: [
    CommonModule,
    CdkTreeModule,
    BreadcrumbsModule,
    FlexLayoutModule,
    FormsModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCheckboxModule,
    MatTooltipModule,
    SecondaryToolbarModule,
    ModulosRoutingModule
  ],
  exports:[ModulosComponent,ModulosEstOrgComponent,ModulosForAssignComponent],
  providers: [ModuloService]
})
export class ModulosModule {
}

