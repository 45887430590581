import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/auth/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  // {
  //   path: 'coming-soon',
  //   loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  // },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'admin',
        children: [
          {
            path: 'nodos',
            loadChildren: () => import('./pages/admin/entidades/tree/tree.module').then(m => m.TreeModule)
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/admin/entidades/users/users.module').then(m => m.UsersModule)
          },
          {
            path: 'roles',
            loadChildren: () => import('./pages/admin/rol-modulo/rol-modulo.module').then(m => m.RolModuloModule)
          },
          {
            path: 'usersrol',
            loadChildren: () => import('./pages/admin/users-rol/users-rol.module').then(m => m.UsersRolModule)
          },
          {
            path: 'usersestorg',
            loadChildren: () => import('./pages/admin/users-est-org/users-est-org.module').then(m => m.UsersEstOrgModule)
          },
          {
            path: 'historial-de-eventos',
            loadChildren: () => import('./pages/admin/bitacora/bitacora.module').then(m => m.BitacoraModule)
          }
        ],
      },
      {
        path: 'ges',
        children: [
          {
            path: 'seguridad/:id',
            loadChildren: () => import('./pages/shared/show-tiles/show-tiles.module').then(m => m.ShowTilesModule)
          },
          {
            path: 'salud/:id',
            loadChildren: () => import('./pages/shared/show-tiles/show-tiles.module').then(m => m.ShowTilesModule)
          },
        ]
      },
      {
        path: 'seguim',
        children: [
          {
            path: 'actas/:id',
            loadChildren: () => import('./pages/shared/show-tiles/show-tiles.module').then(m => m.ShowTilesModule)
          },         
          {
            path: 'seguridad/:id',
            loadChildren: () => import('./pages/shared/show-tiles/show-tiles.module').then(m => m.ShowTilesModule)
          },
          {
            path: 'salud/:id',
            loadChildren: () => import('./pages/shared/show-tiles/show-tiles.module').then(m => m.ShowTilesModule)
          }
        ],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
