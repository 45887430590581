<mat-tree [dataSource]="treeDataModulo" [treeControl]="treeControl" class="example-tree pr-10">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <div class="mat-tree-node hover:bg-hover cursor-pointer"  (click)="onSelection(node)" *ngIf="node.assigned">
      <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
      <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
        {{ node.sociedad || node.grupoCentros || node.centro}}
    </div>
    <div *ngIf="!node.assigned">
      <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
      <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
      {{ node.sociedad || node.grupoCentros || node.centro}}
    </div>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
    <div class="mat-tree-node hover:bg-hover cursor-pointer" (click)="onSelection(node)" *ngIf="node.assigned"> 
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle'">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="treeControl.isExpanded(node)"  [icIcon]="icExpandMore"></mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="!treeControl.isExpanded(node)" [icIcon]="icChevronRight"></mat-icon>
      </button>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
      <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
      {{ node.sociedad || node.grupoCentros || node.centro }} 
    </div>
    <div class="mat-tree-node" *ngIf="!node.assigned">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle'">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="treeControl.isExpanded(node)"  [icIcon]="icExpandMore"></mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="!treeControl.isExpanded(node)" [icIcon]="icChevronRight"></mat-icon>
      </button>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-cyan" *ngIf="node.idSociedad" [icIcon]="icBusiness"></mat-icon>
      <mat-icon class="mat-icon-rtl-mirror m-2 text-purple" *ngIf="node.idGrupoCentros" [icIcon]="icBlur_circular"></mat-icon>
      <ic-icon class="m-2 text-orange" [icon]="icFiber_manual_record" *ngIf="node.idCentro" ></ic-icon>
      {{ node.sociedad || node.grupoCentros || node.centro }} 
    </div>
    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

