import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { userBody } from 'src/app/interfaces/user.model';

import { environment } from 'src/environments/environment';
let headers = new HttpHeaders();

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userURL = environment.userURL;
  constructor(private http: HttpClient) {  }

  getUsers() {
    const body = {action: 'findall'}
    return this.http.post(this.userURL,body);
  }

  getUser(id: number) {
    const body = {action: 'find', payload: {where:{id:id}}};
    return this.http.post(this.userURL,body);
  }
// para borrar devuelve id del usuario.
  getUserForEmail(email: string, idEntitie: number) {
    const body = {email:email, idEntitie: idEntitie};
    return this.http.post(environment.userProfiles,body);
  }

// devuelve datos del usuario
  getDataUser(email: string) {
    headers = headers.set('Authorization', localStorage.getItem('CognitoIdentityServiceProvider.idToken'));
    return this.http.post(environment.dataUser, {email: email},{observe: 'response', headers:headers });
    // return this.http.post(environment.dataUser, {email: email});

  }

  getUserForSociedad(idSociedad: number) {
    const body = {action: 'find', payload: {where:{idSociedad:idSociedad}}};
    return this.http.post(this.userURL,body);
  }

  deleteUser(id: number) {
    const body = {action: 'delete', payload: { id:id}};
    return this.http.post(this.userURL,body);
  }

  saveUser(dataUser: any) {
    const body = { action: 'save', payload: dataUser}
    return this.http.post(this.userURL, body);

  }

  updateUser(body: userBody) { 
    return this.http.post(this.userURL, body);
  }

  updateUserPhoto(dataUserPhoto: any) { 
    const body = { action: 'save', payload: dataUserPhoto}
    return this.http.post(this.userURL, body);
  }

  changeKey(dataUser: any){
    return this.http.post(environment.userChangeKey, dataUser);
  }
}
