import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  items: NavigationItem[] = [];
  entitie = {
    sociedad: '',
    nombreRol: '',
    year: new Date().getFullYear(),
    estOrg: '',
    nameEstOrg: [],
    urlLogo: '',
    modalidadTrabajo:'',
    userName: '',
    userPhoto: ''
  };


  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  private _loadPhotoSubject = new Subject<string>();
  _loadPhoto$ = this._loadPhotoSubject.asObservable();

  constructor() {}

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }
  triggerLoadPhoto(urlPhoto: string) {
    this._loadPhotoSubject.next(urlPhoto);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }
}
