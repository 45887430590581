import { Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import icChevronRight from '@iconify/icons-ic/chevron-right';
import icExpandMore from '@iconify/icons-ic/expand-more';
import icBlur_circular from '@iconify/icons-ic/blur-circular';
import icBusiness from '@iconify/icons-ic/business';
import icFiber_manual_record from '@iconify/icons-ic/fiber-manual-record';
import icLocation_city from '@iconify/icons-ic/location-city';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import { NotifierService } from 'src/app/services/notifier/notifier.service';
import { UserEstorgService } from 'src/app/services/user-estorg/user-estorg.service';
import { EventoService } from 'src/app/services/evento/evento.service';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';

import { resDataTree} from 'src/app/interfaces/entitie.model';

@Component({
  selector: 'vex-modulos-for-assign',
  templateUrl: './modulos-for-assign.component.html',
  styleUrls: ['./modulos-for-assign.component.scss']
})
export class ModulosForAssignComponent implements OnInit  {
  @Input('idUserSelect') idUserSelect: number;
  @Input('treeDataModulo') treeDataModulo: any;
  @Input('flatEntities') flatEntities: any;
  @Input('tituloUser') tituloUser: string;
  
  entitie: number;
  treeControl = new NestedTreeControl<resDataTree>(node => node.sstGrupoCentros || node.sstCentros);
  dataSource = new MatTreeNestedDataSource<resDataTree>();
  
  checklistSelection = new SelectionModel<resDataTree>(true, []);
  isUpdating: boolean = false;
  icExpandMore = icExpandMore;
  icChevronRight = icChevronRight;
  icBusiness = icBusiness;
  icLocation_city = icLocation_city;
  icBlur_circular = icBlur_circular;
  icFiber_manual_record = icFiber_manual_record;
  icMoreHoriz = icMoreHoriz;

  constructor(private userEstorgService: UserEstorgService,
              private eventoService: EventoService,
              private notifierService: NotifierService,
              private appstatusService: AppstatusService) {
                 this.entitie = this.appstatusService.getIdSociedadDefault();
              }

  ngOnInit() {  }

  // hasChild = (_: number, node: Modulo) => !!node.children && node.children.length > 0;
  hasChild = (_: number, node: resDataTree) => (!!node.sstGrupoCentros && node.sstGrupoCentros.length > 0) || (!!node.sstCentros && node.sstCentros.length > 0);
  
  isSelectedNode(node: resDataTree):boolean{
    if (node.isAssigned) {
      this.checklistSelection.select(node);
      node.isAssigned = false;
    }
    return this.checklistSelection.isSelected(node);
  }

  assignModulosToRol() {
    this.isUpdating = true;
    const eoSelected = this.checklistSelection.selected;
    const totalSelected = eoSelected.length;
    let eoSelectedWithParents: resDataTree[] = [];

    eoSelected.forEach((item,idx) => {
      eoSelectedWithParents.push(item);
      eoSelectedWithParents[idx].idParent = this.getParentNode(item);
    });

    this.userEstorgService.deleteAllUserEstOrg(this.idUserSelect,this.entitie).subscribe(resp => {
      if (resp['status']==200) {
        if (totalSelected > 0) {
          this.userEstorgService.saveAllUserEstOrg(this.entitie,this.idUserSelect, eoSelectedWithParents)
            .subscribe(resSaveAll => {
              this.eventoService.saveEvento(this.entitie,'Actualizó '+ resSaveAll.length + ' estructura(s) organizativa(s) a '+ this.tituloUser).subscribe(res=> console.log('log assign ',res));
              this.notifierService.simpleNotification('Asignaciones actualizadas. ','Cerrar',2000);
              this.isUpdating = false;
          });
        } else {
          this.notifierService.simpleNotification('Asignaciones actualizadas ','Cerrar',2000);
          this.isUpdating = false;
        }

      } else {
        this.notifierService.showNotification('Servidor no responde, vuelva intentar','Cerrar','error');
        this.isUpdating = false;
      }
    });
  }

  private findLevel(node: resDataTree) {
    if (Object.keys(node).includes('idSociedad')) {
      return 1;
    }
    if (Object.keys(node).includes('idGrupoCentros')) {
      return 2;
    }
    if (Object.keys(node).includes('idCentro')) {
      return 3;
    }
  }

  private getParentNode(node: resDataTree): number{
    const level = this.findLevel(node);
    if (level == 1) {
      return 0;
    }
    if (level == 2) {
      return this.entitie;
    }
    if (level == 3) {
      let idParentCentro = 0;
      idParentCentro = this.flatEntities.find(element => element.idCentro == node.idCentro).idGrupoCentros;
      return idParentCentro;   
    }
  }
}
