import { Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl} from '@angular/cdk/tree';
import icEdit from '@iconify/icons-ic/edit';
import icEditTwotone from '@iconify/icons-ic/twotone-edit';
import icChevronRight from '@iconify/icons-ic/chevron-right';
import icExpandMore from '@iconify/icons-ic/expand-more';
import icBlur_circular from '@iconify/icons-ic/blur-circular';
import icBusiness from '@iconify/icons-ic/business';
import icFiber_manual_record from '@iconify/icons-ic/fiber-manual-record';
import icLocation_city from '@iconify/icons-ic/location-city';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';

import { resDataTree} from 'src/app/interfaces/entitie.model';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';
import { AdjuntosService } from 'src/app/services/adjuntos/adjuntos.service';

@Component({
  selector: 'vex-modulos-est-org',
  templateUrl: './modulos-est-org.component.html',
  styleUrls: ['./modulos-est-org.component.scss']
})
export class ModulosEstOrgComponent implements OnInit  {

  @Input('treeDataModulo') treeDataModulo: any;

  treeControl = new NestedTreeControl<resDataTree>(node => node.sstGrupoCentros || node.sstCentros);
  dataSource = new MatTreeNestedDataSource<resDataTree>();

  icEdit = icEdit;
  icExpandMore = icExpandMore;
  icChevronRight = icChevronRight;
  icEditTwotone = icEditTwotone;
  icBusiness = icBusiness;
  icLocation_city = icLocation_city;
  icBlur_circular = icBlur_circular;
  icFiber_manual_record = icFiber_manual_record;
  icMoreHoriz = icMoreHoriz;

  constructor(private appstatusService: AppstatusService,
              private adjuntosService: AdjuntosService) { }

  ngOnInit() { }
  
  hasChild = (_: number, node: resDataTree) => (!!node.sstGrupoCentros && node.sstGrupoCentros.length > 0) || (!!node.sstCentros && node.sstCentros.length > 0);

  onSelection(node: resDataTree ) {
    this.appstatusService.setStructure(node.structures,node.structureName);
    this.appstatusService._setUploadFilesButton();
    this.adjuntosService.changeCurrentList();
  }

}
