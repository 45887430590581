import { Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import icEdit from '@iconify/icons-ic/edit';
import icEditTwotone from '@iconify/icons-ic/twotone-edit';
import icChevronRight from '@iconify/icons-ic/chevron-right';
import icExpandMore from '@iconify/icons-ic/expand-more';
import { NotifierService } from 'src/app/services/notifier/notifier.service';
import { RolModuloService } from 'src/app/services/rol-modulo/rol-modulo.service';
import { Modulo  } from 'src/app/interfaces/modulo.model';

@Component({
  selector: 'vex-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit  {

  @Input('rolIdSelect') rolIdSelect: number;
  @Input('treeDataModulo') treeDataModulo: any;
  @Input('dataRolModulo') dataRolModulo: any;

  treeControl = new NestedTreeControl<Modulo>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Modulo>();
  arrRolModulo:Modulo[] = [];
  checklistSelection = new SelectionModel<Modulo>(true, []);
  // editSelection = [];

  icEdit = icEdit;
  icExpandMore = icExpandMore;
  icChevronRight = icChevronRight;
  icEditTwotone = icEditTwotone;
  constructor(private rolModuloService: RolModuloService,
              private notifierService: NotifierService) { }

  ngOnInit() { 
    // this.editSelection = [];
    // console.log(this.editSelection);
  }
  
  hasChild = (_: number, node: Modulo) => !!node.children && node.children.length > 0;

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: Modulo): boolean {
    if (node.activo === '1') {
      this.checklistSelection.select(node);
      node.activo ='0';
    } 
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: Modulo): boolean {
    if (node.activo === '1') {
      this.checklistSelection.select(node);
      node.activo ='0';
    } 
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    if (result) {
      this.checklistSelection.select(node);
    }
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: Modulo): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: Modulo): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: Modulo): void {
    let parent: Modulo | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: Modulo): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: Modulo): Modulo | null {
    const currentLevel = node.parentId;
    if (currentLevel < 1) {
      return null;
    }
    const parents = this.treeControl.expansionModel.selected;
    const currentNodo = parents.filter(parentNode => parentNode.idModulo  == currentLevel);
    console.log(parents);
    console.log(currentNodo);
    console.log(currentNodo[0]);
    return currentNodo[0];
  }

  isSelectedNode(node: Modulo):boolean{
    if (node.activo === '1') {
      this.checklistSelection.select(node);
      node.activo ='0';
    }

    return this.checklistSelection.isSelected(node);
  }

  // isEditSelection(idNode: number) {
  //   return this.editSelection.includes(idNode)
  // }

  // toggleEdit(node: Modulo) {
  //   console.log(this.editSelection);
  //   console.log(node);
  //   if (this.editSelection.includes(node.id)) {
  //     this.editSelection.splice(this.editSelection.indexOf(node.id),1);
  //   } else {
  //     this.editSelection.push(node.id);
  //   }
  //   console.log(this.editSelection);
  // }

  assignModulosToRol()  {
    let isSelectedItem;
    const totalSelected = this.checklistSelection.selected.length;
    console.log(this.checklistSelection);
    if (totalSelected == 0) {
      this.notifierService.simpleNotification('No se realizó ninguna modificación []','Cerrar',2000)
      return;   
    } else {
      this.dataRolModulo.forEach(element => {
        let body = {
          action: 'save',
          payload:{
            id: element,
            activo: '0'
          }
        };
        isSelectedItem = this.checklistSelection.selected.find(item => item.id === element);
        if (isSelectedItem != undefined) {
          body.payload.activo = '1';
        } 
        this.rolModuloService.saveRolModulo(body).subscribe();
      }, (err: string) => this.notifierService.showNotification(err,'Cerrar','error'),
         this.notifierService.showNotification('Tiles modificados.','Cerrar','success'));
    }
 }

}
