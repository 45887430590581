import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID,NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { AppstatusService } from 'src/app/services/appstatus/appstatus.service';
import localeES from '@angular/common/locales/es';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectSessionInterceptor } from './core/interceptors/inject-session.interceptor';
registerLocaleData(localeES, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,

    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [  {provide: LOCALE_ID, useValue: 'es'},
                { provide: HTTP_INTERCEPTORS, useClass: InjectSessionInterceptor, multi: true},
              //  {provide: LocationStrategy, useClass: HashLocationStrategy},
              AppstatusService],
  bootstrap: [AppComponent]
})
export class AppModule { }
