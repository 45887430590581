import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Adjuntos } from 'src/app/interfaces/adjuntos';
import { AppstatusService } from '../appstatus/appstatus.service';
import { NotifierService } from '../notifier/notifier.service';

@Injectable({
  providedIn: 'root'
})
export class AdjuntosService {
  private files = new BehaviorSubject([]);
  currentFiles = this.files.asObservable();
  
  constructor(private http: HttpClient,
              private appstatusService: AppstatusService,
              private notifierService: NotifierService) {  }

  getAdjuntos() {
    const body = {action: 'findall'}
    return this.http.post(environment.adjuntosModulos,body);
  }

  getAdjuntosFilter(dataDefault:any, idModulo: number, dataModule:any, idCentro?: number) {
    const reqCentro = idCentro ? idCentro : dataDefault.structures.c;
    let req = {
      action  : 'find',
      payload : {
        where : {
          idSociedad: dataDefault.entitie,
          idModulo: idModulo,
          periodoYear: dataDefault.grade,
          idGrupoCentros: dataDefault.structures.gc,
          idCentro: reqCentro,
        },
        order: {
          fechaCreacion: 'DESC'
        }
      }
    }
    if (dataModule.currentLabelsModule.length !== 3) {
      if (dataModule.currentLabelsModule[0].label == 'Sociedad' && dataModule.currentLabelsModule.length == 1) { 
        delete req.payload.where.idGrupoCentros;
        delete req.payload.where.idCentro;
      }
      if (dataModule.currentLabelsModule[0].label == 'Centro' && dataModule.currentLabelsModule.length == 1 && dataDefault.structures.gc) { 
        req.payload.where.idGrupoCentros = null;
      }
    }
    return this.http.post(environment.adjuntosModulos,req);
  }

  changeCurrentList(idModulo?: number) {
    const dataModulo = this.appstatusService._getCurrentModuleData();
    const dataDefault = this.appstatusService.getDataDefault();
    if (dataModulo !== undefined) {
      let idCurrentIdModulo = idModulo ? idModulo : dataModulo.idModulo;
      if (dataDefault.structures) {
        if (dataDefault.structures.c == null && dataModulo.currentLabelsModule[0].label == 'Centro') {
          let arrayDocumentsCentros = [];
          const arrayCentros = dataDefault.structures.gc == null
                               ? this.appstatusService._getIdCentrosAssigned()
                               : this.appstatusService._getIdCentrosAssigned(dataDefault.structures.gc);
          arrayCentros.forEach(centro => {
            this.getAdjuntosFilter(dataDefault,idCurrentIdModulo,dataModulo,centro.idEstOrg).subscribe(resDocuments => {
              if (resDocuments['status']==200) {
                arrayDocumentsCentros.push(...resDocuments['data']);
              }
            });
          });
          this.files.next(arrayDocumentsCentros);
        } else {
          this.getAdjuntosFilter(dataDefault,idCurrentIdModulo,dataModulo).subscribe(res => {
            if (res['status']==200) {
              this.files.next(res['data']);
            } else {
              this.notifierService.simpleNotification('El servidor no responde o no tiene conexión','Cerrar',4000);
            }
          });        
        }
      } else {
        alert(dataDefault.structures);
        this.notifierService.simpleNotification('Debe seleccionar una Estructura Organizativa. Realizar click en nombre del usuario','Cerrar',4000);
      }
    }
  }

  saveAdjuntos(body: Adjuntos) {
    let req =  { action:'save', payload:body} ;
    return this.http.post(environment.adjuntosModulos, req);
  }

  updateAdjuntos(body: Adjuntos) { 
    let req =  { action:'save', payload:body} ;
    return this.http.post(environment.adjuntosModulos, req);
  }

  deleteAdjuntos(id: number) {
    const req = {action: 'delete', payload: { idAdjunto:id}};
    return this.http.post(environment.adjuntosModulos, req);
  }

}
