import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { environment, AWSInfo } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  S3_URL= environment.S3_URL

  constructor(private http: HttpClient) { }

  uploadFile(file, contentType,  bucket, dated_filename: boolean) { 
    let url = this.S3_URL+"upload";
    let newFileName = "";
    let fileName = file['name'];
    if (dated_filename) {
      const fileExtension = fileName.substr((fileName.lastIndexOf(".")+1 - fileName.length));
      const today = new Date().getTime();
      newFileName = fileName.substr(0,(fileName.lastIndexOf(".")));
      newFileName+= "-" + today + "." + fileExtension;
    } else {
      newFileName = fileName;
    }
    let body = {
      "bucket": AWSInfo.s3Bucket + bucket,
      "key": newFileName,
      // "key": fileName,
      "contentType":contentType
    }
    return this.http.post(url, body).pipe(
      retry(1),
    ) 
  }

  url(url, file){
    return this.http.put(url, file,{
      reportProgress: true,
      observe: 'events'
    } )
  }

  download(bucket, name) {
    let url = this.S3_URL + "download";
    let body = {
      "bucket":bucket,
      "key":name
    }
    return this.http.post(url, body)
   }

  delete(bucket, name) {
    let url = this.S3_URL+"delete";
    const body = {
      "bucket":bucket,
      "key":name
    }
    return this.http.request('delete', url, { body: body })
  }

}
