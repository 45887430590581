import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { bodyEntitie, tenant } from 'src/app/interfaces/entitie.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntitieService {
  entitieIdURL = environment.entitieIdURL;

  constructor(private http: HttpClient) {  }

  getEntitie(idEntitie: number, level: string) {
    const url = this.setUrl(level);
    const bodyentitie = this.setBodyEntitie(level, 'find', idEntitie);
    return this.http.post(url, bodyentitie);
  }
  
  getEntities(level: string) {
    const url = this.setUrl(level);
    let req ={
      "action": "findall"
    };
    return this.http.post(url, req);
  }

  saveEntitie(bodyentitie: bodyEntitie) {
    const url = this.setUrl(bodyentitie.action);
    bodyentitie.action = 'save';
    bodyentitie.payload.idTenant= tenant;
    return this.http.post(url, bodyentitie);
  }
  
  updateLogoEntitie(bodyentitie: any) {
    const url = this.setUrl(bodyentitie.action);
    const req = {
      action:  'save',
      payload:  bodyentitie
    }
    return this.http.post(environment.sociedad, req);
  }

  deleteEntitie(idEntitie: number, level: string) {
    const url = this.setUrl(level);
    const bodyentitie = this.setBodyEntitie(level, 'delete', idEntitie);
    return this.http.post(url, bodyentitie);
  }

  // Devuelve Id de Grupo Economico
  getIdGrupoEconomico(idEntitie: number) {
    const url = this.setUrl('level2');
    let req ={
      action: 'find',
      payload: {
        where: {
          idTenant: tenant,
          idSociedad: idEntitie
        },
        relations: ['idGrupoEconomico']
      }
    };
    return this.http.post(url, req);
  }

  // Devuelve entidades por Grupo Economico
  getEntitiesByGrupoEconomico(idEntitie: number) {
    const url = this.setUrl('level1');
    let req ={
      action: 'find',
      payload: {
        where: {
          idGrupoEconomico: idEntitie
        },
        relations: ['sstSociedads']
      }
    };
    return this.http.post(url, req);
  }

  // para eliminar Devuelve entidades ordenadas de la sociedad 
  getEntitiesBySociety(idEntitie: number) {
    const url = this.setUrl('level2');
    let req = {
      action: 'find',
      payload: {
        where: { idSociedad: idEntitie },
        relations: ['sstGrupoCentros','sstGrupoCentros.sstCentros']
      }
    };
    return this.http.post(url, req);
  }

  // Devuelve entidades ordenadas de la sociedad tree
  getTreeSociety(idEntitie: number, idUsuario: number) {
    const req = { idSociedad: idEntitie, idUsuario: idUsuario  };
    return this.http.post(environment.treeSociedad, req);
  }

  // ***Devuelve entidades (estructuras organizativas asignadas) ordenadas de la sociedad tree
  getTreeSocietyAssigned(idEntitie: number, idUsuario: number) {
    const req = { idSociedad: idEntitie, idUsuario: idUsuario  };
    return this.http.post(environment.treeSociedad, req)
          .pipe(
            tap(event => localStorage.setItem('eoAssigned', JSON.stringify(event['data']['eoAsignadas']))),
            map(res => (res['status']==200 && res['data']['entidadesAsignadas'].length) ? res['data']['entidadesAsignadas']: [] )
          );
  }

  // Devuelve entidades ordenadas de un grupo economico
  getTree(idGrupoEconomico: number){
    const url = this.entitieIdURL;
    const body = {
      tenant: tenant,
      idGrupoEconomico: idGrupoEconomico
    };
    return this.http.post(url, body);
  }

  // devuelve numero de licencias
  getLicenseNumber(idEntitie: number) {
    const url = this.setUrl('level2');
    let req = {
      action: 'find',
      payload: {
        select: ["numeroLicencias","numeroActivos"],
        where: { idSociedad: idEntitie },
      }
    };
    return this.http.post(url, req);
  }
  
  // aumenta numero de usuarios activos
  updateActiveUsersNumber(idSociedad: number, lastNumber: number) {
    const url = this.setUrl('level2');
    let req = {
      action: 'save',
      payload: {
        idSociedad: idSociedad,
        numeroActivos: lastNumber
      }
    };
    return this.http.post(url, req);
  }

  private setUrl(level: string):string {
    let urlEntitie = '';
    switch (level) {
      case 'level1':
        urlEntitie = environment.grupoEconomico;
        break;
      case 'level2':
        urlEntitie = environment.sociedad;
        break;
      case 'level3':
        urlEntitie = environment.grupoCentro;
        break;
      case 'level4':
        urlEntitie = environment.centro;
    }
    return urlEntitie;
  }

  private setBodyEntitie(level: string, action: string, idEntitie: number):object {
    let bodyEntitie = {};
    switch (level) {
      case 'level1':
        bodyEntitie = { action: action, 'payload': { 'idGrupoEconomico': idEntitie } };
        break;
      case 'level2':
        bodyEntitie = { action: action, 'payload': { 'idSociedad': idEntitie } };
        break;
      case 'level3':
        bodyEntitie = { action: action, 'payload': { 'idGrupoCentros': idEntitie } };
        break;
      case 'level4':
        bodyEntitie = { action: action, 'payload': { 'idCentro': idEntitie } };
    }
    return bodyEntitie;
  }

}
